import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import Container from "../../components/Container/Container";
import FilterSummary from "../../components/Filters/FilterSummary/FilterSummary";
import Seo from "../../components/Seo/Seo";
import SmallHeroNew from "../../components/SmallHeroNew/SmallHeroNew";
import CardGridSpain from "../../components/SpainComponent/SpanjeGrid";
import { useVillaQuery } from "../../hooks/useVillaQuery";
import G from "../../images/groenhout_g.png"
import containsValue from "../../functions/containsValue";

const SpanjeAanbod = () => {

  const [search, setSearch] = useState()
  const [filtered, setFiltered] = useState([])

  const data = useVillaQuery()
  const img = data.allWpVilla.edges[0].node.villas.afbeeldingen[0].afbeelding

  const villas = data.allWpVilla.edges

  const content = data.wpPage.content

  // useEffect(() => {
  //     if (typeof window !== 'undefined') {
  //         const storedScrollPosition = sessionStorage.getItem('scrollPosition');
  //         if (storedScrollPosition) {
  //             window.scrollTo(0, parseInt(storedScrollPosition, 10));
  //             sessionStorage.removeItem('scrollPosition'); // Clear after using to avoid unwanted behavior in future visits
  //         }
  //     }
  // }, []);

  const seo = useStaticQuery(
    graphql`
        query SpanjePage {
            wpPage(slug: {eq: "spanje"}) {
                seo{
                    metaDesc
                    title
                  }
                  content
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
              }
        } `
  )

  const searchHandler = (e) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    const array = villas.filter(node => containsValue(node, search));
    setFiltered(array)
  }, [search])

  useEffect(() => {
    setFiltered(villas)
  }, [])

  console.log(filtered, search)

  return (
    <>
      <Seo title={seo.wpPage.seo.title} description={seo.wpPage.seo.metaDesc}></Seo>
      <SmallHeroNew title={'Spaans aanbod'} overlay={true} overlayImage={G} image={img !== undefined ? img : ''}></SmallHeroNew>
      <Container styles="w-full max-w-none lg:my-16 my-8 px-8 lg:px-0">
        <div className="bg-light text-blue lg:p-8 p-4 mb-8 text-sm"><p dangerouslySetInnerHTML={{ __html: content }}></p></div>
        <FilterSummary count={villas.length} changeHandler={''} type={''} minVal={''} maxVal={''} minOpp={''} maxOpp={''} plaats={''} slaapkamers={''}></FilterSummary>
        <div className="inline-flex flex-col mt-4 w-1/4">
          <label for="zoeken" className="mb-2">Zoek naar een project</label>
          <input type="text" name="zoeken" placeholder="Type hier je zoekopdracht" className="border border-primary px-4 py-3" onKeyUp={searchHandler}></input>
        </div>
        <CardGridSpain data={filtered}></CardGridSpain>
      </Container>
      <div>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: seo.wpPage.content }}></div>
        </Container>
      </div>
    </>
  )
}

export default SpanjeAanbod;