export default function containsValue(obj, valueToCheck) {
    // Convert valueToCheck to lower case (or upper case) for case insensitive comparison
    const lowerCaseValueToCheck = valueToCheck?.toLowerCase();

    // If obj is a string, convert it to lower case before comparison
    if (typeof obj === 'string') {
        return obj.toLowerCase().includes(lowerCaseValueToCheck);
    }

    // If obj is an array, apply containsValue recursively to each element
    if (Array.isArray(obj)) {
        return obj.some(element => containsValue(element, lowerCaseValueToCheck));
    }

    // If obj is an object, apply containsValue to each value
    if (typeof obj === 'object' && obj !== null) {
        return Object.values(obj).some(value => containsValue(value, lowerCaseValueToCheck));
    }

    return false;
}
