import { useStaticQuery, graphql } from "gatsby"
export const useVillaQuery = () => {

  const data = useStaticQuery(
  graphql`
  query VillaQuery {
     allWpVilla {
        edges {
            node{
                title
                uri
                slug
                villas {
                    plaats
                    prijs
                    provincie
                    zwembad
                    locatieDetails
                    bedden
                    badkamers
                    oppervlakten {
                    bouw
                    perceel
                    }
                    locatie {
                    lat
                    long
                    }
                    features {
                    feature
                    }
                    afbeeldingen {
                    afbeelding
                    }
                }
            }
        }    
    }
    wpPage(uri: {eq: "/aanbod/spanje/"}) {
      content
    }
  }
  `
)

  return data;
}

