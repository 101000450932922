import React from "react";
import Container from "../../Container/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const FilterSummary = (props) => {

    let min = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(parseInt(props.minVal))
    let max = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(parseInt(props.maxVal))
    let type = props.type
    let minOpp = `${props.minOpp} m²`
    let maxOpp = `${props.maxOpp} m²`
    let plaats = props.plaats
    let slaapkamers = props.slaapkamers

    const close = <FontAwesomeIcon icon={faTimes} className="icon-spin"/>

    return(
        <Container>
            <div className="border-b-2 border-light flex lg:flex-row flex-col justify-between text-gray text-sm lg:p-0 ml-0">
                <div className="flex lg:flex-row flex-col lg:items-end items-start mb-3">
                <span>We hebben {props.count} {props.count > 1 ? `woningen` : `woning`} voor u gevonden{slaapkamers === '' && props.minVal  === '' && props.maxVal === '' && type === '' && props.minOpp === '' && props.maxOpp === '' && plaats.length === 0 ? '.' :  ` gefilterd op:`} </span>
                    <ul className="lg:ml-3 m-0">
                        {props.minVal !== '' ? <li className="inline p-3 bg-blue text-white mr-3"><button className="mr-3 cursor-pointer" name="remove_single" value="minBedrag" onClick={props.changeHandler}>{close}</button>Minimum bedrag: {min}</li> : null }
                        {props.maxVal !== '' ? <li className="inline p-3 bg-blue text-white mr-3"><button className="mr-3 cursor-pointer" name="remove_single" value="maxBedrag" onClick={props.changeHandler}>{close}</button>Maximum bedrag: {max}</li> : null }
                        {type !== '' ? <li className="inline p-3 bg-blue text-white mr-3"><button className="mr-3 cursor-pointer" name="remove_single" value="type" onClick={props.changeHandler}>{close}</button>Type woning: {type.replaceAll("_", " ")}</li> : null }
                        {props.minOpp !== '' ? <li className="inline p-3 bg-blue text-white mr-3"><button className="mr-3 cursor-pointer" name="remove_single" value="minOpp" onClick={props.changeHandler}>{close}</button>Minimale oppervlakte: {minOpp}</li> : null }
                        {props.maxOpp !== '' ? <li className="inline p-3 bg-blue text-white mr-3"><button className="mr-3 cursor-pointer" name="remove_single" value="maxOpp" onClick={props.changeHandler}>{close}</button>Maximale oppervlakte: {maxOpp}</li> : null }
                        {plaats.length !== 0 ? <li className="inline p-3 bg-blue text-white mr-3"><button className="mr-3 cursor-pointer" name="remove_single" value="Plaatsen" onClick={props.changeHandler}>{close}</button>Plaatsen: {plaats.join(', ')}</li> : null }
                        {slaapkamers !== '' ? <li className="inline p-3 bg-blue text-white mr-3"><button className="mr-3 cursor-pointer" name="remove_single" value="Slaapkamers" onClick={props.changeHandler}>{close}</button>Aantal slaapkamers: {slaapkamers}+</li> : null }
                    </ul>
                </div>
               {slaapkamers === '' && props.minVal  === '' && props.maxVal === '' && type === '' && props.minOpp === '' && props.maxOpp === '' && plaats.length === 0 ? null : <input className="bg-blue text-white px-8 py-3 cursor-pointer" onClick={props.changeHandler} value="Verwijder filters" type="button"  name="remove" ></input>}
            </div>
        </Container>
    )
}

export default FilterSummary