import React, {useContext, useEffect, useState} from "react";
import "../CardGrid/CardGrid.css"
import CardSpain from "./SpanjeCard";

const CardGridSpain = (props) => {

    const data = props.data

    const [page, setPage] = useState(1)
    const [step, setStep] = useState(20)
    const [stateArr, setStateArr] = useState([])

    useEffect(()=>{
        setStateArr([])
        for (let i = 0; i < data.length; i += step) {
            const chunk = data.slice(i, i + step);
            setStateArr(prevStateArr => [...prevStateArr, chunk])
        }

        let params = new URL(document.location).searchParams;
        let currPage = params.get('page')

        if(currPage == null){
            setPage(1)
        }else{
            setPage(currPage)
        }

    }, [data])

    useEffect(()=>{

        const url = new URL(window.location);
        url.searchParams.set('page', page);
        window.history.pushState(null, '', url.toString());

    }, [page])

    const woningen = stateArr[parseInt(page) - 1]?.map((e, i) => (
        <CardSpain data={e.node} key={i} delay={i} length={data.length}></CardSpain>
    ))

    const pageHandler = (id) => {
        setPage(id)

         window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

    }

    const pagination = stateArr.map((e, i)=>{

        return(
            <li className={`cursor-pointer w-10 h-10 flex items-center justify-center mr-2 ${page == (i+1) ? 'bg-blue text-white' : 'bg-light'}`} onClick={()=>{pageHandler(i + 1)}}>{i + 1}</li>
        )
    })

    return(
        <>
            <div className="aanbod__grid my-12 grid lg:grid-cols-3 grid-cols-1 gap-8 full:w-9/12 w-full max-h-auto h-auto lg:p-0 m-0">
                {woningen}
            </div>
            <div className="flex justify-center">
                <ul className="flex flex-row flex-wrap m-0">{pagination}</ul>
            </div>
        </>
    )
}

export default CardGridSpain;